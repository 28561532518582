import React from "react";
import { ReactComponent as Cart } from "../../Assets/Icons/Standard.svg";
import { ReactComponent as AddCart } from "../../Assets/Icons/addCart.svg";
import PropTypes from "prop-types";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
import "./ProductItemSearch.css";
import "./overide.css";

const ProductItemSearch = ({
  item,
  index,
  onClic = () => {
    return;
  },
}) => {
  const SContext = useShoppingCart();
  return (
    <>
      <div
        style={{
          backgroundColor: "#666666",
          width: "100vw",
          height: 1,
          zIndex: 999,
          right: 0,
        }}
      >
        {" "}
      </div>

      <div
        className={"product-item-search"}
        onClick={() => {
          // console.log("Added product in search")
          SContext.addScannedProduct(item.object);
          onClic();
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            "justify-content": "space-evenly",
          }}
        >
          <div className="product-item-search__image" style={{}}>
            <img src={item.image} alt="Product Image" />
          </div>
          <div
            className="product-item-search__details"
            style={{
              width: "70vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="product-item-search__header">
              <h3 className="product-item-search__title">{item.title}</h3>

              <p className="product-item-search__sku">Ref {item.sku}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column",alignItems:"center" ,width: '100px'}}>
              <p className="product-item-search__price">{item.price} €</p>

            {item.date != item.price && <p className="product-item-search__date" style={{alignItems:"center" }}>
                {item.date != item.price ? "Antes " : ""}{" "}
                {item.date != item.price && item.date}{" "}
                {item.date != item.price && "€"}
              </p>} 
             {item.time && <p className="product-item-search__time">{item.time}</p>}
            </div>
            <p
                className="product-item__quantity"
                style={{ backgroundColor: getStatusColor[item.status] }}
              >
                {item.quantity}
              </p>
          </div>
        </div>
      </div>
    </>
  );
};

ProductItemSearch.propTypes = {
  item: PropTypes.shape({
    object: PropTypes.object,
    image: PropTypes.string,
    sku: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    quantity: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default ProductItemSearch;

const getStatusColor = {
  pending: "#C61112",
  finished: "#C5E39E",
  canceled: "#b85922",
};