
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { APICall } from '../API';

const checkInLifeExpectancy = 9000000;
const extraCheckInLifeExpectancy = 3600000;
const INITIALCONTEXT = {
  "list": [],
};

const ShoppingCartContext = createContext(
  INITIALCONTEXT,
);
function ShoppingCartProvider({ children }) {
  const [ShoppingCart, updateShoppingCartState] = useState(INITIALCONTEXT);
  const [canShowEditCart, setcanShowEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  // useEffect(() => {
  //   // console.clear()
  //   console.log("ShoppingCart")
  //   console.log(ShoppingCart)
  //   console.log(ShoppingCart.list)
  // },

  //   // [ShoppingCart, ShoppingCart.list]

  // )

  const addScannedProduct = (object) => {

    updateShoppingCartState((currentCart) => {

      console.log("currentCart index")
      console.log(currentCart)

      const productIndex = currentCart.list.findIndex(
        (product) => product.sku === object.sku
      );

      console.log(productIndex)

      let isProductInList = productIndex > -1;
      

      if (isProductInList) {
        console.log("update quantity")

        let isNotMaxQuantity = currentCart.list[productIndex].quantity + 1 <= object.maxQuantity;

        if (isNotMaxQuantity) {
          let tempList = [...currentCart.list]
          tempList[productIndex].quantity = tempList[productIndex].quantity + 1;
          return { list: tempList }
        }
        else {
          console.log('max quantity');
          return { list: currentCart.list }
        }
      }

      else {
        return {
          list: [...currentCart.list, object]
        }
      }

    });
    setIsOpen(false);
  };


  // const addScannedProduct_Old = (object) => {
  //   const resultProduct = object;
  //   console.log(resultProduct);

  //   if (resultProduct) {
  //     console.log("ENTROU");


  //     if (resultProduct.maxQuantity > 0) {
  //       const tmpshp = ShoppingCart;

  //       const productIndex = tmpshp.list.findIndex(
  //         (product) => product.sku === resultProduct.sku
  //       );
  //       if (productIndex > -1) {
  //         if (
  //           tmpshp.list[productIndex].quantity + 1 >
  //           resultProduct.maxQuantity
  //         ) {
  //           return console.log('max quantity');
  //         }
  //         if (
  //           tmpshp.list[productIndex].quantity + 1 >
  //           resultProduct.maxQuantity
  //         ) {
  //           return console.log('max quantity');
  //         }
  //       }
  //     }
  //   }

  //   if (resultProduct) {
  //     const product = resultProduct;

  //     const tempShopping = { ...ShoppingCart };

  //     console.log("tempShopping")
  //     console.log(tempShopping)

  //     // ShoppingCart.shoppingCart.products
  //     // check if product is already in cart by barcode and add or inccrease quantity
  //     const productIndex = tempShopping.list.findIndex(
  //       (product) => product.sku === resultProduct.sku
  //     );
  //     console.log("productIndex")
  //     console.log(productIndex)

  //     if (productIndex > -1) {
  //       tempShopping.list[productIndex].quantity =
  //         tempShopping.list[productIndex].quantity + 1;

  //       product.quantity =
  //         tempShopping.list[productIndex].quantity;

  //       updateShoppingCartState(tempShopping);


  //       updateShoppingCartState((currentCart) => {


  //         console.log("currentCart index")
  //         console.log(currentCart)

  //         const productIndex = currentCart.list.findIndex(
  //           (product) => product.sku === resultProduct.sku
  //         );


  //         return {
  //           list: [...currentCart.list, object]
  //         }
  //       });


  //     }
  //     else {
  //       // updateShoppingCartState((currentCart) => ({
  //       //   list: [...currentCart.list, object]
  //       // }));

  //       updateShoppingCartState((currentCart) => {
  //         console.log("currentCart")
  //         console.log(currentCart)
  //         return {
  //           list: [...currentCart.list, object]
  //         }
  //       });


  //     }

  //     setIsOpen(false);
  //     // return product;
  //   }
  // };

  const removeItem = (index) => {

    const tempShopping = { ...ShoppingCart }; // Create a shallow copy of ShoppingCart
    tempShopping.list.splice(index, 1);
    updateShoppingCartState(tempShopping);
  };

  const addDiscount = (motivo, preco, id) => {
    const tempShopping = { ...ShoppingCart }; // Create a shallow copy of ShoppingCart

    // Find the product in the shopping cart by ID
    const productIndex = tempShopping.list.findIndex((product) => product.id === id);

    if (productIndex > -1) {
      // Apply the discount to the product

      tempShopping.list[productIndex].discountReason = motivo;

      let discount = tempShopping.list[productIndex].price - (preco * 100)
      tempShopping.list[productIndex].discount = discount;
      tempShopping.list[productIndex].forcedDiscount = true
      // Update the shopping cart state
      updateShoppingCartState(tempShopping);
    } else {
      console.log("Product not found in the shopping cart.");
    }
  };


  const addComment = (comment, id) => {
    const productIndex = ShoppingCart.list.findIndex((product) => product.id === id);

    if (productIndex > -1) {
      ShoppingCart.list[productIndex].comments = comment;
      updateShoppingCartState(ShoppingCart);
    }
  }



  return (
    <ShoppingCartContext.Provider
      value={{
        ShoppingCart,
        updateShoppingCartState,
        isOpen,
        setIsOpen,
        addScannedProduct,
        removeItem,
        addDiscount,
        addComment
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}

function useShoppingCart() {
  const context = useContext(ShoppingCartContext);

  if (!context) {
    throw new Error(
      'useShoppingCart must be used within an ShoppingCartProvider'
    );
  }

  return context;
}

export { ShoppingCartProvider, useShoppingCart };
