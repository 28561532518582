import React, { useEffect, useState } from "react";
import LicencePlateInput from "../../components/LicencePlateInput";
import Button from "../../components/Button";
import { ReactComponent as Cart } from "../../Assets/Icons/Standard.svg";
import { ReactComponent as Voice } from "../../Assets/Icons/keyboard_voice.svg";
import { ArrowArrowBottom32 } from "@mozaic-ds/icons/react/index";
import logo from "./../../Assets/logo.png";
import ProductItem from "../../components/ProductItem";
import TextInput from "../../components/TextInput";
import { useNavigate } from "react-router-dom";
import "./CartScreen.css";
import { useScanner } from "../../Hooks/useQRScanner";
import SearchDropdown from "../../components/Dropdown/Dropdown";
import { APICall } from "../../API";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
import { useHistory } from "../../Context/HistoryContext";
import { useUser } from "../../Context/userContext";
import { useLoadingSpinner } from "../../Context/LoadingContext";

export default function CartScreen() {
  const navigate = useNavigate();
  const scontext = useScanner();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const SContext = useShoppingCart();
  const User = useUser();
  const [qtde, setQtde] = useState(0);
  const [total, setTotal] = useState(0);
  const { addToHistory } = useHistory();
  const { setLoading } = useLoadingSpinner();

  const [list2, setList2] = useState([]);

  //sempre que o carrinho for alterado, atualiza o total e a quantidade
  useEffect(() => {
    let qtde = 0;
    let total = 0;
    if (SContext.ShoppingCart.list) {
      SContext.ShoppingCart.list.forEach((item) => {
        qtde += item.quantity;
        total += item.price * item.quantity;
      });
    }
    setQtde(qtde);
    setTotal(total);
  }, [SContext.ShoppingCart.list]);

  const ItemsCart = () => {
    return (
      <div className="cart-item-div">
        {SContext.ShoppingCart.list ? (
          SContext.ShoppingCart.list.length === 0 ? (
            <div className="stateless">
              <p>Carrinho Vazio!</p>
              <Cart fill="#188803" className="cart-stateless"></Cart>
            </div>
          ) : (
            SContext.ShoppingCart.list.map((item, index) => (
              <ProductItem
                key={index}
                index={index}
                item={{
                  index: index,
                  object: item,
                  image: item.img || logo,
                  sku: item.sku,
                  title: item.name,
                  price: parseFloat(item.price / 100 || 0).toFixed(2),
                  discount: parseFloat(item.discount / 100 || 0).toFixed(2),
                  date: parseFloat(item.price / 100 || 0).toFixed(2),
                  quantity: item.quantity,
                }}
              />
            ))
          )
        ) : null}
      </div>
    );
  };

  const handleOptionSelect = (option) => {
    console.log(`Selected option:`, option);
  };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);

  //   // Agora, você pode atualizar isOpen com base no valor de event.target.value
  //   if (event.target.value === '') {
  //     SContext.setIsOpen(false);
  //   } else {
  //     SContext.setIsOpen(true);
  //   }
  // };

  return (
    <>
      <div className="mainCart">
        <div className="SearchBar" style={{ zIndex: 7 }}>
          <div className="SearchInputDiv">
            <div style={{ width: "100vw" }}>
              <SearchDropdown
                options={list2}
                isOpen={SContext.isOpen}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              //handleSearchChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="divider_cart" style={{ marginTop: 10 }}></div>
        </div>
        <div style={{ height: 84 }}></div>
        <ItemsCart></ItemsCart>
        <div className="bottomCart">
          <div className="divider_cart"></div>
          <div className="quantity_price_div">
            <div className="cart_icon_div">
              <Cart fill="#3A3936"></Cart>
              <h4 className="cart_quantity">
                {SContext.ShoppingCart.list.reduce(
                  (acc, item) => acc + item.quantity,
                  0
                )}
              </h4>
            </div>
            <h4 className="cart_total">
              Total:
              <span className="cart_total_number">
                {" "}
                {SContext.ShoppingCart.list
                  .reduce(
                    (acc, item) =>
                      acc +
                      item.quantity * ((item.price - item.discount) / 100 || 0),
                    0
                  )
                  .toFixed(2)}{" "}
                €
              </span>
            </h4>
          </div>
          <div className="buttons__div">
            {/*  <Button className={"cart-button"} variant={"tertiary"} onClick={()=>{  scontext.openScanner()}}>
            SCAN
  </Button>*/}
            {
              User.isUserOnSameStore() &&
              <Button
                className={"cart-button "}
                iconRight={true}
                disabled={SContext.ShoppingCart.list.length === 0}
                onClick={() => {
                  navigate("/finish");
                  console.log(
                    User.User.matricula.replace(/ /g, "").toUpperCase()
                  );
                  let saleObj = {
                    _className: "ShoppingCart",
                    orderRef: User.User.matricula.replace(/ /g, "").toUpperCase(),
                    amount: parseInt(
                      SContext.ShoppingCart.list.reduce(
                        (acc, item) =>
                          acc + item.quantity * (item.price - item.discount || 0),
                        0
                      )
                    ),
                    deviceId: "1234567890",
                    userId: "90010166",

                    build: 1200,
                    checkinTime: "" + Date.now(),
                    creationDate: "" + Date.now(),
                    deviceConnectionType: "",
                    dummy: false,
                    extraData: {},
                    list: SContext.ShoppingCart.list,
                    storeId: User.User.storeId,
                    udid: "fe5257A53AA5-B527-451E-BC9D-8C81C286F6C8",
                    userData: {
                      email: null,
                      firebaseId: null,
                      isAnonymous: true,
                      name: null,
                      photo: null,
                      statistics: {},
                    },
                    uuid: "57A53AA5-B527-451E-BC9D-8C81C286F6C8",
                  };
                  console.log(saleObj);
                  APICall.newSale(User.User.storeId, saleObj).catch(
                    (err) => {
                      console.log(err)

                    }
                  )
                  addToHistory(saleObj);
                  SContext.ShoppingCart.list = [];
                  User.updateUser({
                    ...User.User,
                    matricula: "",
                  });
                }}
                Icon={() => <Cart fill="white"></Cart>}
              >
                CHECKOUT
              </Button>}
          </div>
        </div>
        {/* {searchTerm !== '' && (
        <div className="cart-div-search"></div>
      )} */}
      </div>
    </>
  );
}
