import React, { useEffect, useState } from "react";
import Modal from "./../../components/Modal";
import "./ModalProduct.css";
import QuantityPicker from "./../../components/QuantityPicker";
import { ReactComponent as QuantityIcon } from "../../Assets/Icons/Quantity.svg";
import { ReactComponent as ReadIcon } from "../../Assets/Icons/Read.svg";
import { ReactComponent as ListIcon } from "../../Assets/Icons/List.svg";

import { useShoppingCart } from "../../Context/ShoppingCartContext";
import QRCode from "react-qr-code";

import Button from "../../components/Button";
import placeholder from "./../../Assets/placeholder-product.png";
import ModalAddComment from "./ModalAddComment/ModalAddComment";
import ModalFicha from "./ModalFicha/ModalFicha";
import ModalPrice from "./ModalPrice";
import ModalStock from "./ModalStock";
import ModalDeleteProduct from "./ModalDeleteProduct";
import logo from "./../../Assets/logo.png";
export default function ModalProduct({
  isHidden,
  onClose,
  produto,
  index,
  setIsHidden,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenF, setModalOpenF] = useState(false);
  const [modalOpenP, setModalOpenP] = useState(false);
  const [modalOpenS, setModalOpenS] = useState(false);
  const [modalOpenD, setModalOpenD] = useState(false);
  const [product, setProduct] = useState(
    produto || {
      name: "Nome do Produto",
      price: "0.00 €",
      maxProduct: "1000",
      quantity: 0,
      img: placeholder,
      sku: "123456789",
    }
  );

  const SContext = useShoppingCart();

  const modalContent = () => {
    return (
      <div className="modal-product-container">
        <div className="product-image-container">
          <img src={product.img || logo} className="product-image"></img>
        </div>
        <div className="product-body-container">
          <div
            className="product-modal-code-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: "10%",
            }}
          >
            <p className="product-modal-name">
              {product.name || "Nome do Produto"}
            </p>

            <ListIcon
              onClick={() => {
                setModalOpenP(true);
                setIsHidden(true);
              }}
              className="product-modal-list-icon"
            ></ListIcon>
          </div>
          <span
            className="product-modal-price"
            style={{
              marginTop: "-10%",
              color: product.discount ? "red" : "black",
            }}
          >
            {" "}
            {getPriceDiscount(product.price, product.discount) || "0.00 €"}
          </span>
          <div className="price-und-container">
            <p className="product-modal-green-price">
              {product.discount != 0 ? "Antes " : ""}
              {parseFloat(product.price / 100).toFixed(2) + " €" || "0.00 €"}
            </p>
            <div className="quantity-container">
              <div>
                <QuantityIcon className={"und-icon"}></QuantityIcon>
              </div>
              <p
                className={"und-price"}
                style={{ marginLeft: "3px", fontSize: "30rem" }}
              >
                {product.storeStock || "0"} <span className="und">UND</span>
              </p>
            </div>
          </div>
          <QuantityPicker
            qtde={product.quantity}
            index={index}
          ></QuantityPicker>
          <div className="read-discount-container">
            
            {/* TODO: Uncomment Ficha de Produto when the  API to serve the URL for the QRCODE is implemented */}
            {/* <div
              onClick={() => {
                setIsHidden(true);
                setModalOpenF(true);
              }}
              className="read-product"
            >
              {" "}
              <ReadIcon
                fill="#188803"
                className={"read-product-icon"}
              ></ReadIcon>
              <p>Ficha de Produto</p>
            </div> */}

            <Button
              onClick={() => {
                setModalOpen(true);
                setIsHidden(true);
              }}
              className="prduct-button-pop-up"
              variant={"shadow"}
            >
              Aplicar desconto
            </Button>
            <div className="flexDiv">
              <div style={{ marginRight: "2%", marginTop: "3%"  }}>
                <Button
                  className="prduct-button-pop-up"
                  onClick={() => {
                    setModalOpenS(true);
                    setIsHidden(true);
                  }}
                  variant={"info"}
                >
                  STOCK
                </Button>
              </div>

              <div style={{ marginRight: "2%", marginTop: "3%" }}>
                <Button
                  className="prduct-button-pop-up"
                  variant={"secondary"}
                  onClick={() => {
                    setModalOpenD(true);
                    setIsHidden(true);
                  }}
                >
                  REMOVER
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        onClose={onClose}
        children={modalContent()}
        isHidden={isHidden}
        confirm={"GUARDAR ALTERAÇÕES"}
      ></Modal>
      <ModalPrice
        isHidden={modalOpen === false}
        data={produto}
        info={""}
        onClose={() => {
          setModalOpen(false);
          setIsHidden(false);
        }}
      ></ModalPrice>
      <ModalFicha
        isHidden={modalOpenF === false}
        info={""}
        id={String(product.sku)}
        onClose={() => {
          setModalOpenF(false);
          setIsHidden(false);
        }}
      ></ModalFicha>
      <ModalAddComment
        isHidden={modalOpenP === false}
        info={product.comments}
        id={product.id}
        sku={String(product.sku)}
        onClose={() => {
          setModalOpenP(false);
          setIsHidden(false);
        }}
      ></ModalAddComment>
      <ModalStock
        sku={String(product.sku)}
        isHidden={modalOpenS === false}
        maxQuantity={product.maxQuantity}
        onClose={() => {
          setModalOpenS(false);
          setIsHidden(false);
        }}
      ></ModalStock>
      <ModalDeleteProduct
        isHidden={modalOpenD === false}
        onClose={() => {
          setModalOpenD(false);
          setIsHidden(false);
        }}
        onConfirm={() => {
          SContext.removeItem(index);
          SContext.updateShoppingCartState({
            ...SContext.ShoppingCart,
            list: [...SContext.ShoppingCart.list],
          });
          onClose();
        }}
      ></ModalDeleteProduct>
    </>
  );
}

const getPriceDiscount = (price, discount = 0) => {
  if (discount === 0) {
    return parseFloat(price / 100).toFixed(2) + " €";
  }

  return parseFloat((price - discount) / 100).toFixed(2) + " €";
};
