import React, { useState, useEffect, useRef } from "react";
import Fuse from "fuse.js";
import ProductItemSearch from "../ProductItemSearch";
import "./Dropdown.css";
import Search from "../../Assets/Icons/Search.png";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
import { APICall } from "../../API";
import { useUser } from "../../Context/userContext";
import { useLoadingSpinner } from "../../Context/LoadingContext";
import logo from "./../../Assets/logo.png";
import Button from "../Button";

let keybuffer = "";
const SearchDropdown = ({
  options,
  searchTerm,
  handleSearchChange,
  isOpen,

  setSearchTerm,
}) => {
  const fuse = new Fuse(options, { keys: ["title"] });
  const [searchTer, setSearchTer] = useState("");

  const SContext = useShoppingCart();
  const User = useUser();
  const [result, setResult] = useState([]);
  const { setLoading } = useLoadingSpinner();

  const inputRef = useRef();

  const ASCII = {
    zero: 48,
    nine: 48 + 9,
    zeroNumpad: 96,
    nineNumpad: 96 + 7,
    enter: 13
  }

  const barcodeMinLength = 7
  let barcodeTimeout

  const testKeycode = (e) => {
    console.log(e)
    console.log("keyCode")

    console.log("e.which" + e.which)
    console.log("e.keyCode" + e.keyCode)
    console.log("e.charCode" + e.charCode)
    console.log("e.key " + e.key)
    console.log("e.code" + e.code)
  }

  function alertKeyCode(e) {

    let kCd = e.which || e.keyCode;

    if (
      (kCd >= ASCII.zero && kCd <= ASCII.nine)
      ||
      (kCd >= ASCII.zeroNumpad && kCd <= ASCII.nineNumpad)
    ) {//number is typed

      let num = kCd - ASCII.zero;
      keybuffer += num.toString();

      //Set watchdog to see if faster than human
      clearTimeout(barcodeTimeout);
      barcodeTimeout = setTimeout(() => {
        keybuffer = ""
      }, 100/*ms*/);

    }

    if (kCd == ASCII.enter && keybuffer.length > barcodeMinLength) {
      // Enter
      setLoading(true);
      APICall.searchProducts(User.User.storeId, keybuffer)
        .then((res) => {
          // console.log(res.data);
          console.log("APICall")
          console.log(SContext.ShoppingCart)

          SContext.addScannedProduct(res.data.list[0]);
          // SContext.updateShoppingCartState({
          //   ...SContext.ShoppingCart,
          //   list: [...SContext.ShoppingCart.list],
          // });
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false);
        })
        .finally(() => {

          setLoading(false);

          //Wait until after load finish to clear input screen
          setTimeout(() => {
            inputRef.current.value = ""
            setSearchTerm("")
            keybuffer = "";
          }, 100/*ms*/);

        });

    }
  }

  useEffect(() => {
    window.addEventListener("keydown", alertKeyCode);
    // window.addEventListener("keydown", testKeycode);

    return () => {
      window.removeEventListener("keydown", alertKeyCode);
      // window.removeEventListener("keydown", testKeycode);
    };
  }, []);

  const doSearchProductsAPI = () => {
    APICall.searchProducts(User.User.storeId, searchTer)
      .then((res) => {
        console.log(res.data);
        setResult(res.data.list);
      })
      .catch((err) => {
        console.log(err);
        setResult([]);
        // setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleSearchChanges = (event) => {
    setSearchTer(event.target.value);
    if (event.target.value !== "") {
      SContext.setIsOpen(true);
    } else {
      SContext.setIsOpen(false);

    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter" && keybuffer < barcodeMinLength) {
      setLoading(true);
      doSearchProductsAPI()
    }
  };



  return (
    <>
      <div
        className="search-dropdown"
        style={{ overflowY: "auto", overflowX: "clip" }}
      >
        <div
          className="search-bar"
          style={{ display: "flex", alignItems: "center" }}
        >
          <input
            ref={inputRef}
            type="search"
            placeholder="Procurar"
            value={searchTer}
            onChange={handleSearchChanges}
            onKeyDown={handleEnterPress}
            className="mc-left-icon-input__input mc-text-input mc-field__input"
            style={{ marginRight: "10px" }}
          />
          <Button
            style={{ marginTop: "5px", width: 48, height: 48 }}
            onClick={() => {
              console.log("button clocked")
              setLoading(true);
              //setSearchTer('');
              doSearchProductsAPI()
            }}
            className={"cart-button cart-button-80"}
          >
            <img src={Search} style={{ width: 28, height: 28 }}></img>
          </Button>
        </div>

        {isOpen && (
          <div className={"div-input"} style={{ marginTop: 20 }}>
            {result.map((item, index) => (
              <>
                <ProductItemSearch
                  index={index}
                  key={index}
                  onClic={() => {
                    {
                      setSearchTer("");
                      SContext.setIsOpen(false);
                      setResult([]);
                    }
                  }}
                  item={{
                    object: item,
                    image: item.img || logo,
                    sku: item.sku,
                    title: item.name,
                    price: getPriceDiscount(item.price, item.discount),
                    date: parseFloat(item.price / 100 || 0).toFixed(2),
                    quantity: item.quantity,
                  }}
                />
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchDropdown;

const getPriceDiscount = (price = 0, discount = 0) => {
  console.log(price);
  if (price == "NaN") {
    price = 0;
  }
  if (discount === 0) {
    return parseFloat(price / 100).toFixed(2);
  }

  return parseFloat(price / 100 - discount / 100).toFixed(2);
};
