import React, { useEffect, useState } from "react";
import Modal from "./../../components/Modal";
import "./ModalStock.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import TextInput from "./../../components/TextInput";
import Select from "./../../components/Select";
import { ReactComponent as QuantityIcon } from "../../Assets/Icons/Quantity.svg";
import { APICall } from "../../API";
import { useUser } from "../../Context/userContext";
import { useLoadingSpinner } from "../../Context/LoadingContext";

export default function ModalStock({ isHidden, maxQuantity, onClose, sku }) {
  const User = useUser();
  const [list, setlist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (sku && !isHidden) {

      APICall.stockBySku(User.User.storeId, sku).then((res) => {
        setIsLoading(false);
        console.log(res);
        let listd = [];
        for (const key in res.data) {
          if (Object.hasOwnProperty.call(res.data, key)) {
            console.log(key, res.data[key]);
            const element = res.data[key];
            let objj = {
              label: key,
              und: element,
            };
            listd.push(objj);
          }
        }

        setlist(listd);
      }).catch(
        (err) => {
          console.log(err)
          setIsLoading(false)

        }
      ).finally(() => {
        setIsLoading(false)
      }
      )
    }
  }, [sku, isHidden]);

  const modalContent = () => {
    return (
      <div className="modal-stock-container">


        <div className="modal-stock-header">
          <h3 className="modal-stock-title">Stock</h3>
          <QuantityIcon className={"stock-icon"}></QuantityIcon>
        </div>

        <div className="modal-stock-list-container">
        {isLoading && <div style={{display:"flex"}}><LoadingSpinner whitebackground={true}></LoadingSpinner></div>}
        {list.map((e) => (
            <>
              <div className="modal-stock-list-item">
                <p className="modal-stock-item-name">{e.label}</p>
                <p className="modal-stock-item-price">
                  {e.und}
                  <span style={{ color: "#188803" }}> UND</span>
                </p>
              </div>
              <div className="modal-stock-divider"></div>
            </>
          ))}

     
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        onConfirm={onClose}
        onClose={onClose}
        children={modalContent()}
        confirm={"OK"}
        isHidden={isHidden}
      ></Modal>
    </>
  );
}
