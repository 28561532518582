
import React, { useEffect, useState } from "react";
import LicencePlateInput from "../../components/LicencePlateInput";
import Button from "../../components/Button";
import { ReactComponent as Cart } from "../../Assets/Icons/Standard.svg";
import { ReactComponent as Voice } from "../../Assets/Icons/keyboard_voice.svg";
import { ArrowArrowBottom32 } from "@mozaic-ds/icons/react/index";
import { ReactComponent as HistoryIcon } from "../../Assets/Icons/history.svg";
import "./HistoryProductsScreen.css";
import ProductItem from "../../components/ProductItem";
import TextInput from "../../components/TextInput";
import Tabs from "../../components/Tabs";
import ModalProduct from "../Modal/ModalProduct";
import { useHistory } from "../../Context/HistoryContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function HistoryProductsScreen() {
  const [searchParams] = useSearchParams();
  const [openTab, setOpenTab] = useState(0);
  const [query, setQuery] = useState("");
  const {History} = useHistory()
  const {index} = useParams();
  const historyItem = History.find(item => item.id+"" == parseInt(index))
  const [list, setList] = useState(
    [
      {
        image: "https://i.ibb.co/WkmYtDR/Group-150.png",
        title: "PLC GESSO CART HIDRO 13MM 2000 GF",
        price: "8888.88 €",
        date: "31/01/2023",
        time: "12:15h",
        quantity: "5",
        status: "pendent"
      },
      {
        image: "https://i.ibb.co/WkmYtDR/Group-150.png",
        title: "PAIN LA ROCHA PN30 135X60X4CM",
        price: "8888.88 €",
        date: "31/02/2023",
        time: "12:15h",
        quantity: "75",
        status: "accepted"
      },
      {
        image: "https://i.ibb.co/WkmYtDR/Group-150.png",
        title: "ANDAIME MASTER A+B+C+D 5.9M",
        price: "8888.88 €",
        date: "31/01/2023",
        time: "12:15h",
        quantity: "1",
        status: "pendent"
      },
      {
        image: "https://i.ibb.co/WkmYtDR/Group-150.png",
        title: "PAINEL SAND 5 ONDAS 30MM 2FC VM 4000",
        price: "8888.88 €",
        date: "27/01/2022",
        time: "12:15h",
        quantity: "100",
        status: "accepted"
      },
      {
        image: "https://i.ibb.co/WkmYtDR/Group-150.png",
        title: "BLOCO BETAO 50X20X15CM V&V",
        price: "8888.88 €",
        date: "11/01/2023",
        time: "12:15h",
        quantity: "1000",
        status: "pendent"
      },
      {
        image: "https://i.ibb.co/WkmYtDR/Group-150.png",
        title: "CIM COLA AXTON PORC GELINT/EXT BR 25KG",
        price: "8888.88 €",
        date: "31/04/2023",
        time: "12:15h",
        quantity: "15",
        status: "accepted"
      },
    ]
  );

  
  
  const ItemsCart = () => {
    return (
      //preciso que faça um map de list, usando title, quantity e price
      <div style={{ overflowY: "auto", maxHeight: "100vh", overflow: "hidden"}}>
  {(historyItem.sale_data.list).map((item, index) => (   
   <div
   style={{
     width: "100%",
     display: "flex",
     background: "#F2F2F2",
     justifyContent: "space-between",
     alignItems: "center",
     padding: "10px",
     overflow: "hidden",
   }}
 >
   <p className="titleHistoryNoBold" style={{ width: "10%", marginLeft: "5%",  }}>{item.quantity} X</p>
   <p className="titleHistoryNoBold" style={{ width: "33%", margin: 0 }}>{item.name}</p>
   <p className="titleHistoryNoBold" style={{ width: "20%", marginRight: "5%", textAlign:"right" }}>{parseFloat(item.price / 100).toFixed(2)} €</p>
 </div>

  ))}
</div>
              

    );
  };

  return (
    <div className="mainHistory2">
      <div className="HistoryTopDiv">
      <div className="SearchBar">
        <div className="SearchInputDiv">
          <div className="titleHistory2" style={{ width: "100vw" }}>
          <p>Produtos do Carrinho</p>
          </div>
        </div>
        <div className="divider_cart" style={{ marginTop: 10 }}></div>
      </div>
        <div style={{ marginTop: 1, width: "100vw" }}></div>
      </div>
       {historyItem && <ItemsCart></ItemsCart>}
       {historyItem && <div className="titleHistory2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "93%", marginLeft: "5%"}}>
          <p>TOTAL</p>
          <p>{
               (historyItem.sale_data.list).reduce(
                (acc, item) =>
                  acc + item.quantity * (item.price / 100 || 0),
                0
              ).toFixed(2)
            } €</p>
          </div>}
    </div>
  );
}
