import React, { useState, useEffect } from "react";

import MainLogo from "./../../Assets/logo.png";
import TextInput from "./../../components/TextInput";
import PasswordInput from "./../../components/PasswordInput";
import Button from "./../../components/Button";
import { Navigate, useNavigate } from "react-router-dom";
import "./LoginScreen.css";
import ModalQrCode from "../Modal/ModalQrCode";
import Notification from "./../../components/Notification";
import { useHistory } from "../../Context/HistoryContext";
import Loader from "../../components/Loader";
import { APICall } from "../../API";
import { useUser } from "../../Context/userContext";
import { useCancela } from "../../Context/CancelaContext";

export default function LoginScreen() {
  const version = "v1.0.5"
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { User, logout, isUserOnSameStore } = useUser()
  const { addToHistory } = useHistory();
  const [isHidden, setIsHidden] = useState(false);
  const {eraseCancelas} = useCancela()
  const handleLogin = () => {
    if (User.authData) {
      if (!isUserOnSameStore()) {
        APICall.logout()
        logout()
        eraseCancelas()
        return
      }
      if (window.location.origin.includes("localhost")) {
        navigate("/menu");
      } else {
        window.location.href = APICall.getLoginUrl();
      }
    }
    else {

      window.location.href = APICall.getLoginUrl();

    }
  };

  useEffect(() => {
    addToHistory();
  }, []);
  if (User.authData) {

    if (isUserOnSameStore()) {

      return (
        <Navigate to="/menu" replace />
      )
    }

    return (
      <>
        <div className="login-container">
          <img
            className="login-logo"
            alt="Leroy Logo"
            src={MainLogo}
            onClick={() => {
              if (isHidden) {
                setIsHidden(false);
              } else {
                setIsHidden(true);
              }
            }}
          />

          {true && (
            <div>
              <h1 className="welcome-title">Bem-vindo ao</h1>
              <h1 className="welcome-brand">Compra Ágil</h1>
              <p className="version">{version}</p>
            </div>
          )}
          <div className="logo-input-container">
            <Notification
              className="notification"
              show={(!isUserOnSameStore())}
              title={"Esta conta não tem permissão para aceder à Compra Ágil"}
              body={
                "Para solicitar o acesso, por favor faça um pedido da categoria “Acesso ou privilēgios” a partir do Digital Service Desk."
              }
            ></Notification>
          </div>
          <Button className={"login-button"} onClick={handleLogin} full={true}>
            {(!isUserOnSameStore()) ? "VOLTAR" : "ENTRAR"}
          </Button>
          {<ModalQrCode isHidden={true}></ModalQrCode>}
        </div>
      </>
    );
  }
  else {

    return (
      <>
        <div className="login-container">
          <img
            className="login-logo"
            alt="Leroy Logo"
            src={MainLogo}
            onClick={() => {
              if (isHidden) {
                setIsHidden(false);
              } else {
                setIsHidden(true);
              }
            }}
          />

          {true && (
            <div>
              <h1 className="welcome-title">Bem-vindo ao</h1>
              <h1 className="welcome-brand">Compra Ágil</h1>
              <p className="version">{version}</p>
            </div>
          )}
          {/* <div className="logo-input-container">
          <Notification
            className="notification"
            show={(User.authData.store_id+"" != User.storeId+"")}
            title={"Esta conta não tem permissão para aceder à Compra Ágil"}
            body={
              "Para solicitar o acesso, por favor faça um pedido da categoria “Acesso ou privilēgios” a partir do Digital Service Desk."
            }
          ></Notification>
        </div> */}
          <Button className={"login-button"} onClick={handleLogin} full={true}>
            {"ENTRAR"}
          </Button>
          {<ModalQrCode isHidden={true}></ModalQrCode>}
        </div>
      </>
    );
  }
}
