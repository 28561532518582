import "./SwipeableList.css";
import React, { useRef, useEffect, useState } from "react";
import imageTrash from "../../Assets/Icons/Trashbin.png";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
function SwipeableListItem({ item, disabled,isHistoryScreen, disableDelete, index,onPointerLeaveCapture,setHideDelete }) {
  const listElementRef = useRef();
  const wrapperRef = useRef();
  const backgroundRef = useRef();
 
  const [canExecuteClickAction, setcanExecuteClickAction] = useState(true);
  const SContext = useShoppingCart();

  const dragStartXRef = useRef(0);
  const leftRef = useRef(0);
  const draggedRef = useRef(false);
  const getStatusColor = {
    pending: "#C61112",
    finished: "#C5E39E",
    canceled: "#b85922",
  };
  useEffect(() => {
    window.addEventListener("mouseup", onDragEndMouse);
    window.addEventListener("touchend", onDragEndTouch);
    return () => {
      window.removeEventListener("mouseup", onDragEndMouse);
      window.removeEventListener("touchend", onDragEndTouch);
    };
  });

  function onDragStartMouse(evt) {
    if (disableDelete) return;
    onDragStart(evt.clientX);
    window.addEventListener("mousemove", onMouseMove);
 

  }

  function onDragStartTouch(evt) {
    if (disableDelete) return;

    console.log(evt.targetTouches);
    console.log(evt.targetTouches[0]);
    const touch = evt.touches[0];

    onDragStart(touch.clientX);
    window.addEventListener("touchmove", onTouchMove);


  }

  function onDragStart(clientX) {
    setcanExecuteClickAction(false);
    draggedRef.current = true;
    dragStartXRef.current = clientX;

    listElementRef.current.className = "ListItem";

    requestAnimationFrame(updatePosition);
  }

  function updatePosition() {
    if (draggedRef.current) {
      requestAnimationFrame(updatePosition);
    }
    if (!disableDelete) {

    listElementRef.current.style.transform = `translateX(${leftRef.current}px)`;
    }
  }

  function onMouseMove(evt) {
  
    const left = evt.clientX - dragStartXRef.current;
    if (left < 0) {
      
      leftRef.current = left;
      setcanExecuteClickAction(false);
    }
  }

  function onTouchMove(evt) {

  const touch = evt.touches[0];
    const left = touch.clientX - dragStartXRef.current;
    if (left < 0) {
      leftRef.current = left;
         setcanExecuteClickAction(false);
    }
  }

  function onDragEndMouse(evt) {
    window.removeEventListener("mousemove", onMouseMove);
    onDragEnd();
    setcanExecuteClickAction(true);

  }

  function onDragEndTouch(evt) {
    
    window.removeEventListener("touchmove", onTouchMove);
    onDragEnd();
    setcanExecuteClickAction(true)
  }

  function onDragEnd() {
    if (draggedRef.current) {
      draggedRef.current = false;
      const threshold = 0.8;
      setcanExecuteClickAction(false)


      if (
        leftRef.current <
        listElementRef.current.offsetWidth * threshold * -1
      ) {
       
        leftRef.current = -listElementRef.current.offsetWidth * 2;
       
        // if (window.confirm("내역을 삭제하시겠습니까?")) {
        //   //confirm delete
        //   wrapperRef.current.style.maxHeight = 0;
        // } else {
        //   leftRef.current = 0;
        // }

        // wrapperRef.current.style.maxHeight = 0;
        setHideDelete(false);
      
        leftRef.current = 0;
      } else {
        
        leftRef.current = 0;
      }

      listElementRef.current.className = "BouncingListItem";
      listElementRef.current.style.transform = `translateX(${leftRef.current}px)`;
    }
  }

  return (
    <>
      <div className="Wrapper" ref={wrapperRef}>
        <div className="Background" ref={backgroundRef}>
          <img width={40} style={{ width: 40 }} src={imageTrash}></img>
        </div>
        
        <div
          className="BouncingListItem"
          ref={listElementRef}
          onMouseDown={onDragStartMouse}
          onTouchStart={onDragStartTouch}
          onClick={canExecuteClickAction ? onPointerLeaveCapture: null}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              opacity: disabled ? 0.5 : 1,
              width: "98%",
            }}
          >
            <div
              className="product-item__image"
            
            >
              <img  src={item.image} alt="Product Image" />
            </div>
            <div
              className="product-item__details"
              style={{
                width: "98%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
               <div className="product-item-search__header">
              <h3 className="product-item-search__title">{item.title}</h3>

              <p className="product-item-search__sku">Ref {item.sku}</p>
            </div>

 <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                'max-width':'100px'
              }}
            >
              <p className="product-item-search__price">{getPriceDiscount(item.price,item.discount)} </p>

              {item.discount != 0 && (
                <p
                  className="product-item-search__date"
                  style={{ alignItems: "center",color:"#188803" }}
                >
                {item.discount != 0 ?"Antes ":""}{item.date} €
                </p>
              )}
              {item.time && (
                <p className="product-item-search__time">{item.time}</p>
              )}
            </div>              <p
                className="product-item__quantity"
                style={{ backgroundColor: getStatusColor[item.status] }}
              >
                {item.quantity}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SwipeableListItem;
const getPriceDiscount = (price=0, discount=0) => {
  console.log(price)
  console.log(discount)
  if(price == "NaN"){
    price = 0
  }
  if (discount === 0) {

   return parseFloat(price ).toFixed(2) + " €"
 }

return parseFloat((price-discount)).toFixed(2)  + " €"
}
